// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import React from 'react';
import { notify } from '../notification';
import { Reducer } from 'redux';
import client from '../client';
import { setCustomerTokenAction } from './customer';
import { ApplicationAction, AppThunkAction } from './index';
import { setRequestingAction } from './request';
import { Charge } from 'stronghold-pay-js';
import { setPaymentSourceAction } from './paymentSource';

export interface TransactionState {
    charge: Charge | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetChargeAction {
    type: 'SET_CHARGE';
    payload: Charge;
}
export const setChargeAction = (payload: Charge): SetChargeAction => ({ type: 'SET_CHARGE', payload });

export type KnownAction = SetChargeAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addCharge: ({
        chargeAmount = 8950,
        tipAmount,
        paymentSourceId,
    }: {
        chargeAmount?: number;
        tipAmount?: number;
        paymentSourceId?: string;
    } = {}): AppThunkAction<ApplicationAction> => async (dispatch, getState) => {
        const apiHost = getState().configuration.config.apiHost;
        const publishableKey = getState().configuration.aggregator?.publishableKey || '';
        const customerId = getState().customer.id || undefined;

        dispatch(setRequestingAction('add_charge'));
        try {
            const customer = await client.getCustomerToken(customerId);
            dispatch({ type: 'SET_CUSTOMER_ID', payload: customer.id });
            dispatch(setCustomerTokenAction(customer.token));
            if (!paymentSourceId) {
                const paymentSource = await client.createSandboxPaymentSource(customer.id);
                dispatch(setPaymentSourceAction(paymentSource));
                paymentSourceId = paymentSource.id;
            }

            const strongholdPay = Stronghold.Pay({
                host: apiHost,
                environment: Stronghold.ENVIRONMENT.sandbox,
                publishableKey: publishableKey,
            });
            strongholdPay.charge(customer.token, {
                charge: {
                    amount: chargeAmount,
                    paymentSourceId: paymentSourceId,
                },
                tip: tipAmount
                    ? {
                          amount: tipAmount,
                          beneficiaryName: 'John',
                      }
                    : undefined,
                onSuccess: (charge) => {
                    notify(
                        <span>
                            Charge with id <strong>${charge.id}</strong> created.
                        </span>,
                    );
                    dispatch(setChargeAction(charge));
                },
                onReady: () => dispatch(setRequestingAction()),
            });
        } catch {
            dispatch(setRequestingAction());
        }
    },

    addCardCharge: (data: {
        amount: number;
        token: string;
        expirationDate: string;
        cvv2: string;
        cardType: string;
        maskedCardNum: string;
        cardHolderName: string;
        zipCode: string;
    }): AppThunkAction<ApplicationAction> => async (dispatch, getState) => {
        dispatch(setRequestingAction('add_charge'));
        try {
            await client.createCardCharge(
                data.amount,
                data.token,
                data.expirationDate,
                data.cvv2,
                data.cardType,
                data.cardHolderName,
                data.zipCode,
            );
            notify(
                <span>
                    Successfully charged <strong>card ending in {data.maskedCardNum}</strong> for{' '}
                    <strong>${data.amount / 100}</strong>.
                </span>,
            );
        } catch (e) {
            dispatch(setRequestingAction());
            console.log(e);
        } finally {
            dispatch(setRequestingAction());
        }
    },

    generateTsepManifest: (): AppThunkAction<ApplicationAction> => async (dispatch, getState) => {
        try {
            const data = await client.generateTsepManifest();
            return data;
        } catch (e) {
            console.log(e);
        }
    },

    addTip: ({ tipAmount = 300 }: { tipAmount?: number } = {}): AppThunkAction<ApplicationAction> => async (
        dispatch,
        getState,
    ) => {
        const apiHost = getState().configuration.config.apiHost;
        const publishableKey = getState().configuration.aggregator?.publishableKey || '';
        const customerId = getState().customer.id || undefined;

        dispatch(setRequestingAction('add_tip'));
        try {
            const customer = await client.getCustomerToken(customerId);
            dispatch({ type: 'SET_CUSTOMER_ID', payload: customer.id });
            dispatch(setCustomerTokenAction(customer.token));
            const paymentSource = await client.createSandboxPaymentSource(customer.id);
            dispatch(setPaymentSourceAction(paymentSource));
            const charge = await client.createCharge(customer.id, paymentSource.id);
            dispatch(setChargeAction(charge));

            const strongholdPay = Stronghold.Pay({
                host: apiHost,
                environment: Stronghold.ENVIRONMENT.sandbox,
                publishableKey: publishableKey,
            });
            strongholdPay.tip(customer.token, {
                tip: {
                    amount: tipAmount,
                    paymentSourceId: paymentSource.id,
                    chargeId: charge.id,
                    beneficiaryName: 'John',
                },
                onSuccess: (tip) => {
                    notify(
                        <span>
                            <p>
                                Tip with id <strong>${tip.id}</strong> created.
                            </p>
                            <div>
                                For the original charge <strong>${charge.id}</strong>{' '}
                            </div>
                        </span>,
                    );
                },
                onReady: () => dispatch(setRequestingAction()),
            });
        } catch {
            dispatch(setRequestingAction());
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TransactionState = {
    charge: null,
};

export const reducer: Reducer<TransactionState, KnownAction> = (
    state: TransactionState = unloadedState,
    action: KnownAction,
): TransactionState => {
    switch (action.type) {
        case 'SET_CHARGE':
            return {
                ...state,
                charge: action.payload,
            };
        default:
            return state;
    }
};
