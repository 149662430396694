// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { Reducer } from 'redux';

type RequestName =
    | 'add_payment_source'
    | 'update_payment_source'
    | 'add_charge'
    | 'add_tip'
    | 'create_pay_link'
    | 'add_card_source';

export interface RequestState {
    requesting: RequestName | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetRequestingAction {
    type: 'SET_REQUESTING';
    payload: RequestName | null;
}
export const setRequestingAction = (requestName?: RequestName): SetRequestingAction => ({
    type: 'SET_REQUESTING',
    payload: requestName || null,
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetRequestingAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: RequestState = {
    requesting: null,
};

export const reducer: Reducer<RequestState, KnownAction> = (
    state: RequestState = unloadedState,
    action: KnownAction,
): RequestState => {
    switch (action.type) {
        case 'SET_REQUESTING':
            return {
                ...state,
                requesting: action.payload,
            };
        default:
            return state;
    }
};
