import { GraphQLClient } from 'graphql-request';
import { Charge, PaymentSource } from 'stronghold-pay-js';
import { PayLinkConfiguration } from '../store/payLink';
import {
    configurationQuery,
    getCustomerTokenQuery,
    resetPaymentSourceMutation,
    createSandboxPaymentSourceMutation,
    createChargeMutation,
    createPayLinkMutation,
    createCardChargeMutation,
    generateTsepManifest,
} from './queries';
import { AggregatorName, Configuration, GetCustomerTokenResponse } from './types';

class Client {
    private _client: GraphQLClient;

    constructor() {
        this._client = new GraphQLClient('/graphql');
    }

    async getConfiguration(): Promise<Configuration> {
        const data = await this._client.request<{ configuration: Configuration }>(configurationQuery);
        return data.configuration;
    }

    async getCustomerToken(id?: string): Promise<GetCustomerTokenResponse> {
        const variables = { id };

        const data = await this._client.request<{
            customer: GetCustomerTokenResponse;
        }>(getCustomerTokenQuery, variables);
        return data.customer;
    }

    async resetPaymentSource(id: string, aggregator: AggregatorName) {
        const variables = { id, aggregator };
        const data = await this._client.request<{ resetPaymentSource: string }>(resetPaymentSourceMutation, variables);
        return data;
    }

    async createSandboxPaymentSource(customerId: string) {
        const variables = { customerId };
        const data = await this._client.request<{ createSandboxPaymentSource: PaymentSource }>(
            createSandboxPaymentSourceMutation,
            variables,
        );
        return data.createSandboxPaymentSource;
    }

    async createCharge(customerId: string, paymentSourceId: string) {
        const variables = { customerId, paymentSourceId };
        const data = await this._client.request<{ createCharge: Charge }>(createChargeMutation, variables);
        return data.createCharge;
    }

    async createCardCharge(
        amount: number,
        token: string,
        expirationDate: string,
        cvv2: string,
        cardType: string,
        cardHolderName: string,
        zipCode: string,
    ) {
        try {
            const variables = { amount, token, expirationDate, cvv2, cardType, cardHolderName, zipCode };
            const data = await this._client.request(createCardChargeMutation, variables);
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    async generateTsepManifest() {
        try {
            const data = await this._client.request(generateTsepManifest);
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    async createPayLink(configuration: PayLinkConfiguration) {
        const variables = configuration;
        const data = await this._client.request<{ createPayLink: { id: string; url: string } }>(
            createPayLinkMutation,
            variables,
        );
        return data.createPayLink;
    }
}

export * from './types';
export default new Client();
